.main-slide {
    height: auto;
    margin-top: 130px;
}

.home-reg-title {
    padding-top: 20px;
    padding-bottom: 20px;
    color: white;
    font-size: 20px;
    text-align: center;
}

.home-top-title {
    color: #1E4D1B;
    font-size: 26px;
    text-align: center;
}

.home-main-back {
    width: 100%;
    height: auto;
    background-color: #1E4D1B;
}

.logo-middle-rect {
    position: absolute;
    top: 15%;
    width: 100%;
}

.home-logo-img {
    margin-left: 50px;
}

.home-info-img {
    margin-left: 50px;
}

.home-main-back-img {
    width: 100%;
    height: auto;
    position: relative;
}

.slide-view {
    background: linear-gradient(90deg, #7FB522 0%, #7FB522 35%, #7FB522 100%);
}

.slide-img-rect {
    text-align: center;
    padding-top: 120px;
}

.slide-img {
    width: 100%;
    max-height: 800px;
    height: auto;
}

.slide-left {
    padding-top: 100px;
}

.slide-title {
    font-size: 40px;
}

.slide-description {
    font-size: 20px;
}

.alert-rect {
    padding-top: 120px;
    height: 100%;
    margin: auto;
    text-align: center;
}

.alert-img {
    width: 100%;
    max-width: 500px;
    padding-top: 100px;
    height: auto;
    margin: auto;
    display: block;
    text-align: center;
}

.alert-text {
    color: var(--markColor);
    font-size: 25px;
    display: inline-block;
    position: absolute;
    top: 306px;
    text-align: center;
    margin-left: -115px;
}

.alert-text p {
    margin-bottom: 0px;
}

.padding-title {
    margin-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
}

.padding-description {
    padding: 0px 50px 50px 50px;
}

.app-img:hover {
    opacity: 0.7;
}

.join-btn {
    border: 1px solid var(--markColor);
    border-radius: 25px;
    padding: 8px 20px;
    color: var(--markColor);
    width: 220px;
    font-weight: 700;
    font-size: 18px;
    margin: auto;
}

.join-btn-rect {}

.forgot-title {
    padding-left: 10px;
    padding-right: 50px;
    margin-top: 10px;
    color: var(--markColor);
    cursor: pointer;
    font-size: 16px;
    text-decoration: underline;
}

.login-section {
    margin: auto;
}

.home-logo-icon {
    width: 300px;
    height: auto;
}

.home-click-icon {
    width: 200px;
    height: auto;
    cursor: pointer;
}

@media screen and (max-width:1204px) {
    .slide-view {
        margin-top: 30px;
    }

    .slide-img-rect {
        padding-top: 20px;
    }

    .login-section {
        margin: auto;
    }
}

@media screen and (max-width:768px) {
    .main-slide {
        margin-top: 90px;
    }

    .home-logo-img {
        margin-left: 20px;
    }

    .home-logo-icon {
        width: 70px;
        height: auto;
    }

    .home-click-icon {
        width: 120px;
        height: auto;
    }

    .slide-left {
        padding-top: 0px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .slide-title {
        font-size: 20px;
    }

    .slide-description {
        font-size: 14px;
    }

    .join-btn {
        padding: 6px 14px;
        width: 150px;
        font-size: 14px;
    }

    .join-btn-rect {
        text-align: center;
        margin-top: 20px;
    }

    .login-section {
        margin: auto;
    }

    .padding-title {
        margin-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .padding-description {
        padding-left: 30px;
        padding-right: 30px;
    }

    .alert-rect {
        padding-top: 120px;
        height: 100%;
        margin: auto;
        text-align: center;
    }

    .alert-img {
        width: 100%;
        max-width: 400px;
        padding-top: 50px;
        height: auto;
        margin: auto;
        display: block;
        text-align: center;
    }

    .alert-text {
        font-size: 16px;
        display: inline-block;
        position: absolute;
        top: 225px;
        text-align: center;
        margin-left: -60px;
    }

    .forgot-title {
        font-size: 12px;
    }

    .login-section img {
        width: 100% !important;
        height: auto !important;
    }
}