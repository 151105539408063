.register-page {
    margin-left: 40px;
    margin-right: 40px;
    padding: 40px;
    width: 70%;
    margin: auto;
}
.register-page .step {
    margin: auto;
    width: 55%;
    padding: 20px 0px;
}
.register-page .steps-content {
    text-align: center;
    min-height: 500px;
}
  
.steps-action {
    margin-top: 24px;
    margin: auto;
    width: 55%;
    padding-bottom: 40px;
}
.register-next-button {
    width: 170px;
    padding: 12px 68px !important;
}
.register-cancel-button {
    background-color: var(--whiteColor) !important;
    color: var(--blackColor) !important;
    text-decoration: underline;
}
.register-prev-button {
    background-color: var(--whiteColor) !important;
    color: var(--mainColor) !important;
    border: 2px solid var(--mainColor);
    width: 180px;
    padding: 10px 50px !important;
}
.register-purchase-button {
    width: 150px;
    padding: 12px 50px !important;
}

.register-page .ant-steps-item-icon {
    width: 38px;
    height: 38px;
    line-height: 38px;
}
.register-page .title {
    padding-top: 20px;
    text-align: center;
    font-family: semiBoldFont;
    font-size: 30px;
    color: var(--blackColor);
    font-weight: bold;
}
.register-page .sub-title {
    text-align: center;
    font-family: regularFont;
    font-size: 20px;
    color: var(--blackColor);
}
.valid-rect {
    border: 1px solid var(--redColor);
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    color: var(--redColor);
    font-size: 16px;
    margin-bottom: 20px;
}
@media screen and (max-width: 1400px) {
    .register-page {
        width: 80%;
        padding: 40px;
    }
}
@media screen and (max-width: 768px) {
    .register-page {
        width: 100%;
        padding: 10px;
    }
    .register-page .common-background {
        margin-top: 0px;
    }
    .register-page .step {
        padding: 0px;
        width: 100%;
        margin-top: 15px;
    }
    .register-page .steps-content {
        margin-top: 20px;
    }
    .steps-action {
        width: 100%;
        padding-bottom: 20px;
    }
    .register-next-button {
        width: 120px;
        padding: 8px 48px !important;
    }
    .register-prev-button {
        width: 100px;
        padding: 8px 32px !important;
    }
    .register-purchase-button {
        width: 100px;
        padding: 8px 32px !important;
    }
    
    .register-page .ant-steps-item-icon {
        width: 26px;
        height: 26px;
        line-height: 26px;
    }
    .register-page .title {
        padding-top: 30px;
        font-size: 20px;
    }
    .register-page .sub-title {
        font-size: 14px;
    }
    .agreement-page .content {
        font-size: 14px;
        padding: 10px 2px;
        width: 100%;
    }
    .valid-rect {
        border-radius: 6px;
        padding: 5px;
        font-size: 12px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
    }
}