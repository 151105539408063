.dashboard-main-page {
    width: 100%;
    height: auto;
    margin: auto;
    overflow: hidden;
    background-color: var(--whiteColor);

}

.nav {
    position: fixed;
    text-align: center;
    margin: auto;
    padding: 30px 100px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    background-color: transparent;
    width: 100%;
    z-index: 1000;
}

.nav.nav-active {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    margin: auto;
    background-color: #ffffff;
    transition: all 0.35s ease-in-out 0s;
    box-shadow: rgb(43 83 135 / 8%) 0px 0px 8px 0px;
}

.img-logo {
    width: 250px;
    height: auto;
}

.nav-login-btn {
    border: 1px solid var(--mainColor);
    border-radius: 25px;
    padding: 8px 20px;
    color: var(--mainColor);
    min-width: 120px;
    font-weight: 700;
}

.nav-login-btn.btn-active {
    border: 1px solid var(--mainColor);
    color: var(--mainColor);
    border-radius: 25px;
}

.main-page-footer {
    padding: 30px;
    background-color: #678d2a;
    text-align: center;
    color: var(--whiteColor);
    font-size: 16px;
}

@media screen and (max-width:1204px) {
    .nav-login-btn {
        border: 1px solid var(--mainColor);
        color: var(--mainColor);
    }
}
@media screen and (max-width:768px) {
    .nav {
        padding: 20px 30px;
    }
    .img-logo {
        width: 200px;
        height: auto;
    }
    .nav-login-btn {
        padding: 6px 14px;
        min-width: 80px;
        font-size: 14px;
    }
    .main-page-footer {
        padding: 20px;
        font-size: 14px;
    }
}