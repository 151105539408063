.main-background-rect {
  color: var(--blackColor);
  background-size: cover !important;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat !important;
}
.logo-rects {
  padding-left: 50px;
  padding-right: 50px;
}
.email-input .ant-input-affix-wrapper {
  height: 40px !important;
  border-radius: 16px !important;
  background-color: var(--markSemiColor) !important;
  padding: 0;
  font-family: regularFont;
  font-size: 16px !important;
}
.email-input .ant-input-prefix {
  margin-left: 4px;
  margin-right: 0px;
  padding: 0px 10px;
}
.email-input .ant-input-affix-wrapper > input.ant-input {
  padding-left: 8px;
  font-size: 16px;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
}

@media screen and (max-width: 1024px) {
  .email-input .ant-input-affix-wrapper > input.ant-input {
    /* height: 35px !important;
    border-radius: 5px !important;
    padding-left: 8px;
    padding-right: 8px; */
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .email-input .ant-input-affix-wrapper > input.ant-input {
    /* height: 35px !important;
    border-radius: 4px !important;
    padding-left: 6px;
    padding-right: 6px; */
    font-size: 12px;
  }
}

@media screen and (max-width: 576px) {
  .logo-rects {
    padding-left: 10px;
    padding-right: 0px;
  }
}