.auth-dialog-widget {
  width: 450px;
  min-width: 450px;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  margin: auto;
}

.auth-dialog-widget > .title {
  font-size: 24px;
  font-family: semiBoldFont;
  margin-top: 30px;
  margin-bottom: 30px;
  color: white;
}

.auth-dialog-widget > .title .sub_title{
  font-size: 12px;
  font-family: semiBoldFont;
  margin-top: 10px;
  margin-bottom: 30px;
  color: white;
}

@media screen and (max-width: 1024px) {
  .auth-dialog-widget {
    max-width: 400px;
    min-width: 100vw;
    margin-top: 20px;
  }

  .auth-dialog-widget > .title {
    font-size: 26px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 576px) {
  .auth-dialog-widget {
    max-width: 350px;
    margin-top: 0px;
  }

  .auth-dialog-widget > .title {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
