.method-rect {
    padding: 20px;
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;
}

.method-rect:hover {
    cursor: pointer;
    opacity: 0.8;
}

.image-rect {
    display: inline-block;
}

.check-rect {
    display: inline-block;
    position: absolute;
    margin-left: -20px;
    margin-top: 65px;
}

.method-image {
    width: 300px;
    height: auto;
}

.method-check-image {
    width: 30px;
    height: 30px;
}

.pay-check {
    font-size: 20px;
    color: brown;
}

.pay-check .ant-checkbox-inner {
    width: 25px;
    height: 25px;
}

.pay-check .ant-checkbox-checked::after {
    border: 1px solid brown;
}

.pay-check .ant-checkbox-checked .ant-checkbox-inner {
    background-color: brown;
    border-color: brown;
}

.pay-check .ant-checkbox-inner {
    border-color: brown;
}

.pay-check .ant-checkbox-inner:hover {
    border-color: brown;
}

/* .pay-check .ant-checkbox-wrapper:hover .ant-checkbox-inner, .pay-check .ant-checkbox:hover .ant-checkbox-inner, .pay-check .ant-checkbox-input:focus + .pay-check .ant-checkbox-inner {
    border-color: brown;
} */
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    /* border-color: brown; */
}

.discount-rect {
    height: 70px;
    margin-top: 10px;
}

.discount-valid {
    padding-top: 5px;
    color: var(--redColor);
    height: 25px;
}

.alert-info-rect {
    padding: 10px;
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
}

@media screen and (max-width: 768px) {
    .method-rect {
        padding: 10px;
        display: inline-block;
        margin-left: 15px;
        margin-right: 15px;
    }

    .method-image {
        width: 200px;
        height: auto;
    }

    .check-rect {
        margin-left: 30px;
        margin-top: 40px;
    }

    .discount-rect {
        height: 110px;
    }

    .discount-valid {
        height: 15px;
    }

    .pay-check {
        font-size: 15px;
    }

    .pay-check .ant-checkbox-inner {
        width: 20px;
        height: 20px;
    }
}