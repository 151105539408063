.welcome {
    width: 100%;
    height: calc( 100vh - 342px);
    margin-top: 200px;
    text-align: center;
    font-size: 24px;
    background-color: var(--whiteColor);
}
.welcome-title {
    color: var(--mainColor);
    margin-top: 50px;
}
.welcome-subtitle {
    font-size: 18px;
}