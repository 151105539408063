.custom-input {
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    background: var(--backgroundColor);
    padding: 8px 12px
}
.custom-input .ant-input {
    background: var(--backgroundColor);
}

.profile-top-background {
    background: var(--blueColor);
    height: 200px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 5%);
}
.avatar-block {
    margin-top:-60px;
    text-align: center;
}
.custom-input .PhoneInputInput {
    border:none;
    outline: none;
    height: 23px;
    background-color: var(--backgroundColor);
}
.custom-input .PhoneInputInput:focus-visible {
    border:none;
    outline: none;
}
/* .trade-box.trade-box-ex-container .trade-form .percentage .per-item:hover {
    border-color: #24a0f5;
  } */
  
.custom-input.PhoneInput.PhoneInput--focus {
    border: 1px solid var(--blueColor);
}
.custom-input.PhoneInput.PhoneInput:hover {
    border: 1px solid var(--blueColor);
    box-shadow: 0 0 0 2px rgb(96 168 220 / 20%);
}
.custom-input.PhoneInput .PhoneInputInput[disabled] {
    cursor: not-allowed;
    opacity: 1;
}
.PhoneInputCountrySelectArrow {
    display: none;
}
.PhoneInputCountryIcon {
    width: 33px !important;
    height: 22px !important;
}
.profile-update-button {
    margin-top: 30px;
}
.profile-avatar-txt {
    text-align: center;
    margin-top: 80px;
}
