.contact-section {
    height: auto;
    padding: 100px;
    background: linear-gradient(90deg, #7FB522 0%, #7FB522 35%, #7FB522 100%);
}
.contactus-title {
    color: var(--whiteColor);
    font-size: 40px;
}
.contactus-description {
    color: var(--whiteColor);
    font-size: 20px;
    opacity: 0.8;
}
.contact-form-title {
    padding-bottom: 4px;
    color: var(--whiteColor);
    font-size: 16px;
}
.contact-form-input {
    height: 42px;
    line-height: 42px;
    font-size: 16px;
}
.contact-form-textarea {
    line-height: 42px;
    font-size: 16px;
}
.contactus-btn {
    border: 1px solid var(--whiteColor);
    border-radius: 25px;
    padding: 8px 20px;
    color: var(--whiteColor);
    width: 220px;
    font-weight: 700;
    font-weight: 700;
    font-size: 18px;
}

@media screen and (max-width:768px) {
    .contact-section {
        padding: 30px;
    }
    .contactus-title {
        font-size: 20px;
    }
    .contactus-description {
        font-size: 14px;
    }
    .contact-form-title {
        padding-bottom: 2px;
        font-size: 14px;
    }
    .contact-form-input {
        height: 30px;
        line-height: 30px;
        font-size: 14px;
    }
    .contact-form-textarea {
        line-height: 30px;
        font-size: 14px;
    }
    .contactus-btn {
        padding: 6px 14px;
        font-size: 14px;
    }
    
    
}