.common-background {
    width: 100%;
    padding: 20px;
    margin-top: 20px;
    background-color: var(--whiteColor);
    border-radius: 14px;
    -webkit-box-shadow: 0 0 15px 0 rgb(0 0 0 / 5%);
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 5%);
}
@media screen and (max-width: 768px){
    .common-background {
        padding: 10px;
    }
}
