/* ::-webkit-scrollbar {
    width: 12px;
    cursor: pointer;
}

::-webkit-scrollbar-track {
    cursor: pointer;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    cursor: pointer;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0);
}  */
.ant-table-tbody>tr>td {
    border-bottom: none;
}
.ant-btn-primary {
    background: var(--mainColor);
    border-color: var(--mainColor);
}
.ant-btn-primary:focus, .ant-btn-primary:hover {
    background: #97cd39;
    border-color: #97cd39;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background: var(--mainColor);
}

.ant-steps-item-process .ant-steps-item-icon {
    border-color: var(--mainColor);
}

.ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: var(--mainColor);
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: var(--mainColor);
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: var(--mainColor);
}

.ant-modal .ant-select-arrow {
    width: 14px;
    height: 14px;
    font-size: 14px;
}
.ant-picker {
    height: 33px;
}
.common-input {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background: var(--backgroundColor);
    padding: 6px 6px;
    font-size: 12px;
}

.common-input-error {
    border: 1px solid #ff8787;
    border-radius: 4px;
    background: var(--backgroundColor);
    padding: 6px 6px;
    font-size: 12px;
}

.common-input .ant-input {
    background: var(--backgroundColor);
}

.common-select {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background: var(--backgroundColor);
    padding: 2px 0px;
    font-size: 12px;
}

.common-select:hover {
    border-color: #8bc4e8;
    border-right-width: 1px !important;
}

.common-select:focus {
    border-color: #8bc4e8;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(96 168 220 / 20%);
}

.common-select-error {
    width: 100%;
    border: 1px solid #ff8787 !important;
    border-radius: 4px;
    background: var(--backgroundColor);
    padding: 1px 0px;
    font-size: 12px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px;
    border: none;
    background: var(--backgroundColor);
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-weight: 400;
}
.ant-input-number {
    width: 100% !important;
}