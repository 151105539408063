.new-image-upload-dialog > .ant-modal-content {
  background-color: transparent !important;
}
.new-image-upload-dialog > * .ant-modal-header {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  background-color: var(--whiteColor);
}
.new-image-upload-dialog > * .ant-modal-body {
  background-color: var(--whiteColor);
}
.new-image-upload-dialog > * .ant-modal-footer {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  background-color: var(--whiteColor);
}
.new-image-upload-dialog > * .ant-modal-footer .ant-btn{
  border-radius: 8px;
  padding: 5px 32px;
  height: 32px;
}
.new-image-upload-container {
  text-align: center;
}

.new-image-upload-image {
  padding: 10px;
  border: 1px solid grey;
  margin-bottom: 10px;
  border-radius: 5px;
  max-width: 300px;
}
.new_image_uploader{
    margin-top: 20px;
}
.new-image-upload-image-button {
  
  background-color: var(--blueColor);
  width: 300px;
  padding: 10px 30px;
  border-radius: 10px;
  color: white;
  font-size: 14px;
  font-family: regularFont;
}
