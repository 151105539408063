.app-section {
    height: auto;
    padding: 100px;
    background-color: #edf1f5;
}
@media screen and (max-width:768px) {
    .app-section {
        padding: 30px;
    }
    .app-img {
        width: 120px;
        height: auto;
    }
    
}